body {
  margin: 0;
}
@font-face {
  font-family: Babyground;
  src: url('./assets/fonts/Babyground.ttf') format('truetype');
}
@font-face {
  font-family: LittleBaby;
  src: url('./assets/fonts/LittleBaby.ttf') format('truetype');
}

@import '../../fonts.scss';
.footer {
  font-family: LittleBaby;
  height: 200px;
  background-color: #eb4773;
  letter-spacing: 5px;
  word-spacing: 5px;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 5px rgba(33, 33, 33, 0.5), 3px 3px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  h4,
  h3,
  h6 {
    margin: 0;
  }
  img {
    width: 80px;
  }
}

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
  .footer {
    height: 150px;
    font-size: 25px;
    img {
      width: 60px;
    }
  }
}
@media screen and (max-width: 480px) {
  // .footer {
  // }
}
@media screen and (max-width: 320px) {
  .footer {
    height: 130px;
    font-size: 19px;

    img {
      width: 47px;
    }
  }
}
